<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat color="white">
        <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrar($event)" max-width="300px" prepend-inner-icon="search" :label="constantes.BOTAO_FILTRAR_EMPRESAS"></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="nova()">{{constantes.BOTAO_NOVA_EMPRESA}}</v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-data-table :options.sync="options" :server-items-length="total" :footer-props="footerOptions" :headers="headers" :items="empresas" :search="busca" :loading="carregando">
        <template v-slot:item="props">
          <tr>
            <td class="text-no-wrap">{{ props.item.cnpj | formatarCnpj }}</td>
            <td>{{ props.item.ie }}</td>
            <td>{{ props.item.razao }}</td>
            <td>{{ props.item.tipo_certificado }}</td>
            <td >{{ props.item.ambiente | codigoParaAmbiente }}</td>
            <td class="text-center">

              <v-menu top right transition="slide-x-transition">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">more_vert</v-icon>
                </template>
                <v-list dense>
                  <v-list-item :key="props.item._id" @click="editar(props.item)">
                    <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item :key="props.item._id + 15">
                    <v-list-item-icon>
                      <v-switch class="mt-0" hide-details v-model="props.item.ativa" @click="changeStatus(props.item)" :loading="carregando"></v-switch>
                    </v-list-item-icon>
                    <v-list-item-title>{{ props.item.ativa ? 'Inativar' : 'Ativar'}}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>

  import {mapState, mapActions} from 'vuex';
  import constantes from '@/util/constantes';

  export default {
    
    data () {
      return {
        headers: [
          { text: 'CNPJ', value: 'cnpj',  sortable: false },
          { text: 'Inscrição Estadual', value: 'inscricao',  sortable: false },
          { text: 'Razão Social', value: 'razao',  sortable: false },
          { text: 'Tipo Cert.', value: 'tipo_certificado',  sortable: false },
          { text: 'Ambiente', value: 'ambiente',  sortable: false },
          { text: 'Ações', value: 'name', align:'left',  sortable: false }
        ],
        constantes: constantes,
        busca: '',
        options: {
          itemsPerPage: 10,
          page: 1
        },
        footerOptions: {
          'items-per-page-options': constantes.ITEMS_POR_PAGINA 
        }
      }
    },

    watch: {
      options: {
        handler () {
          this.carregar();
        },
        deep: true,
      },
    },

    computed: {
        
      ...mapState('empresa', {
        empresas: 'empresas',
        carregando: 'carregando',
        total: 'totalEmpresas'
      }),

      ehEmpresaPadrao(empresa){
        return this.$store.state.empresaAtual._id === empresa._id;
      }
    },

    methods: {

      editar(empresa){
        this.$store.commit('empresa/editar', empresa);
        this.$router.push('/empresasAdmin/editar');
      },

      nova(){
        this.$store.commit('empresa/novo');
        this.$router.push('/empresasAdmin/nova');
      },

      carregar(){
        this.$store.dispatch('empresa/carregarTodas', this.options);
      },

      limparBusca(){
        this.carregar();
      },

      filtrar(event){

        let newOptions = {
          ...this.options,
          query: this.busca
        }

        this.$store.dispatch('empresa/carregarTodas', newOptions);
        setTimeout(() => {
          event.target.select();
        }, 300)
      },

      changeStatus(empresa){

        if(empresa.ativa){
          this.inativar(empresa);
        }
        else{
          this.ativar(empresa);
        }
      },

      inativar(empresa){
        this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja inativar?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.dispatch('empresa/inativar', empresa);
          }
        });
      },

      ativar(empresa){
        this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja ativar?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.dispatch('empresa/ativar', empresa);
          }
        });
      }
    }
  }
</script>